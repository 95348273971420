import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";

const planClient = axios.create({
    baseURL: '/api/v1/plan/'
});

// Add the current team ID to all plan requests.
planClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

export default {
    /**
     * Generate and test the uniqueness of a slug on the server.
     * @param {string} table
     * @param {string} slug
     * @returns {Promise<>}
     */
    generateSlug(table, slug) {
        return planClient.get(`slug`, {
            params: {
                ctx: table,
                slug: slug
            }
        });
    },
    /**
     * Fetch all output profiles.
     * @returns {Promise<array|null>}
     */
    getOutputProfiles(parameters) {
        let params = {
            page: parameters.page,
            per_page: parameters.perPage,
        }

        if (parameters.sortBy) {
            params.sort_by = parameters.sortBy;
            params.sort = parameters.sort;
        }

        return planClient.get('output-profiles', {params});
    },
    /**
     * Fetch a specific output profile from the server.
     * @param {number} id
     * @returns {Promise<object|null>}
     */
    getOutputProfile: id=> planClient.get(`output-profiles/${id}`),
    /**
     * Store the given data as an output profile on the server.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeOutputProfile: attr => planClient.post(`output-profiles`, attr, {
        headers: {
            'content-type': 'multipart/form-data', // Support uploaded images.
        }
    }),
    /**
     * Update the given output profile.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateOutputProfile: (id, attr)=> {
        attr._method = 'PUT';

        return planClient.post(`output-profiles/${id}`, attr, {
            headers: {
                'content-type': 'multipart/form-data', // Support uploaded images.
            }
        });
    },
    /**
     * Delete an output profile.
     * @param {number} id
     * @returns {Promise<>}
     */
    deleteOutputProfile: id => planClient.delete(`output-profiles/${id}`),
    /**
     * Fetch all campaigns.
     * @returns {Promise<array|null>}
     */
    getCampaigns(page, per_page) {
        return planClient.get('campaigns', {
            params: {
                page,
                per_page
            }
        });
    },
    /**
     * Return matched campaigns.
     * @param {string} term
     * @return {Promise<void>|*}
     */
    searchCampaigns: term => {
        if (!term) return Promise.resolve();
        return planClient.get('campaigns/search', {params: {s: term}});
    },
    /**
     * Total number of campaigns for current team.
     * @return {Promise<number>}
     */
    getCampaignCount: () => planClient.get('campaigns/count'),
    /**
     * Fetch a specific campaign from the server.
     * @param {number} id
     * @returns {Promise<object>}
     */
    getCampaign: id => planClient.get(`campaigns/${id}`),
    /**
     * Store the given data as an campaigns on the server.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeCampaign: attr => planClient.post(`campaigns`, attr),
    /**
     * Update the given campaign.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateCampaign: (id, attr) => planClient.put(`campaigns/${id}`, attr),
    /**
     * Delete a campaign.
     * @returns {Promise<>}
     */
    deleteCampaign: id => planClient.delete(`campaigns/${id}`),
    /**
     * Fetch all advertisements.
     * @returns {Promise<array|null>}
     */
    getAdvertisements(page, per_page) {
        return planClient.get('advertisements', {
            params: {
                page,
                per_page
            }
        });
    },
    /**
     * Fetch a specific advertisement from the server.
     * @param {number} id
     * @returns {Promise<object>}
     */
    getAdvertisement: id => planClient.get(`advertisements/${id}`),
    /**
     * Store the given data as a advertisement on the server.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeAdvertisement: attr => planClient.post(`advertisements`, attr),
    /**
     * Update the given advertisement.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateAdvertisement: (id, attr) => planClient.put(`advertisements/${id}`, attr),
    /**
     * Delete an advertisement.
     * @returns {Promise<>}
     */
    deleteAdvertisement: id => planClient.delete(`advertisements/${id}`),
};
