import types from "../Shared/storeTypes.js";
import axiosConfig from "../Application/Services/axios.js";
import teamClient from "./teamClient.js";

const
    state = {
        [types.TEAM_CURRENT]: null,
        [types.TEAMS]: [],
        [types.TEAMS_TOTAL]: 0,
        [types.TEAM_SUBSCRIBED]: false,
    },
    mutations = {
        [types.TEAM_CURRENT](state, team) {
            state[types.TEAM_CURRENT] = team;
            state[types.TEAM_SUBSCRIBED] = team ? !!team.subscribed : false;
        },
        [types.TEAMS](state, teams) {
            state[types.TEAMS] = teams;
        },
        [types.TEAMS_TOTAL](state, count) {
            state[types.TEAMS_TOTAL] = count;
        },
        updateTeam(state, id, team) {
            const index = state[types.TEAMS].findIndex(team => team.id === id);

            state[types.TEAMS] = state[types.TEAMS].splice(index, 1, team);
        }
    },
    getters = {
        /**
         * Return the team of the authenticated user.
         * @param {object} state
         * @return {object}
         */
        current: state => state[types.TEAM_CURRENT],
        /**
         * Return the team by its id.
         * @param {object} state
         * @return {object}
         */
        team: state => id => state[types.TEAMS].filter(team => team.id === id).pop(),
        /**
         * Return all the teams.
         * @param {object} state
         * @return {object}
         */
        teams: state => state[types.TEAMS],
        /**
         * Return the teams count.
         * @param {object} state
         * @return {object}
         */
        total: state => state[types.TEAMS_TOTAL],
        /**
         * Return the subscription state of the team of the authenticated user.
         * @param {object} state
         * @return {boolean}
         */
        subscribed: state => state[types.TEAM_SUBSCRIBED],
        /**
         * Check if the team can create more output profiles.
         * TODO retrieve from Spark plan
         * TODO Retrieve count with initial team GET?
         * NOTE: this should be called when the profiles are loaded!
         * @param state
         * @param getters
         * @param rootState
         * @return boolean
         */
        canCreateOutputProfiles: (state, getters, rootState) => {
            // const outputProfilesCount = rootState.outputProfiles.output_profiles.length;

            return true;
        },
        /**
         * Check if the team can create more members.
         * @param state
         * @param getters
         * @param rootState
         * @return boolean
         */
        canCreateMembers: (state, getters, rootState) => {
            // TODO retrieve from Spark plan
            // TODO Retrieve count with initial team GET?
            //const teamMembersCount = rootState.teamMembers.members.length;

            return true; //teamMembersCount < 10;
        },
    },
    helpers = {
        shouldFetchAnew(parameters, teams) {
            return parameters.refresh
                || parameters.sortBy
                || !teams.length;
        },
        /**
         * Cleanup data before storage on server.
         * @param {object} data
         * @returns {object}
         */
        sanitize(data) {
            delete data.id;
            delete data.created_at;
            delete data.updated_at;

            return data;
        },
    },
    actions = {
        /**
         *
         * @param commit
         * @param dispatch
         * @param team
         */
        setCurrentTeam({commit, dispatch}, team) {
            commit(types.TEAM_CURRENT, team);

            dispatch('notifications/setUnreadCount', team.unread_notification_count, {root: true});

            axiosConfig.setCurrentTeam(team.id);
        },
        /**
         *
         * @param commit
         * @param dispatch
         */
        unsetCurrentTeam({commit, dispatch}) {
            commit(types.TEAM_CURRENT, null);

            dispatch('notifications/setUnreadCount', 0, {root: true});

            axiosConfig.unsetCurrentTeam();
        },
        /**
         * Fetch the resources.
         * @description Output profiles are never paginated.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<array|null>}
         */
        index({commit, getters}, parameters = {}) {
            const teams = getters.teams;

            if (!helpers.shouldFetchAnew(parameters, teams)) {
                return Promise.resolve(teams);
            }

            return teamClient
                .getTeams()
                .then(response => {
                    commit(types.TEAMS, response.data.data);
                    commit(types.TEAMS_TOTAL, response.data.data.length);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const teams = getters.team(id);

            if (teams) {
                return Promise.resolve(teams);
            }

            return teamClient.getTeam(id)
                .then(response => response.data.data);
        },
        /**
         * Update the resource and fetch the current page again.
         * @param {function} commit
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        update({commit}, {id, attr}) {
            attr = helpers.sanitize(attr);

            return teamClient.updateTeam(id, attr).then(response => {
                commit('updateTeam', response.data.data.id, response.data.data);

                return response.data.data;
            });
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {function} getters
         * @param {function} commit
         * @param {object} attr
         * @return {Promise<object>}
         */
        store({dispatch, getters, commit}, attr) {
            attr = helpers.sanitize(attr);

            return teamClient.storeTeam(attr)
                .then(response => response.data.data)
                .then(model => {
                    commit(types.TEAMS_TOTAL, getters.total + 1);

                    // Make sure the store is refreshed.
                    dispatch('index', {refresh: true});

                    return model
                });
        },
        /**
         * Delete the resource and fetch the current list again.
         * @param {function} dispatch
         * @param {function} getters
         * @param {function} commit
         * @param {object} model
         * @return {Promise<void>}
         */
        destroy({dispatch, getters, commit}, model) {
            return teamClient
                .deleteTeam(model)
                .then(() => {
                    commit(types.TEAMS_TOTAL, getters.total - 1);

                    // Make sure the store is refreshed.
                    dispatch('index', {refresh: true});
                });
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
